<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix mb-5" style="margin-top: 100px">
    <vs-input
      v-validate="'required|min:5'"
      data-vv-validate-on="blur"
      @keydown.space.prevent
      label-placeholder="UserName"
      name="UserName"
      :placeholder="$t('UserName')"
      v-model="UserName"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("UserName") }}</span>

    <vs-input
      data-vv-validate-on="blur"
      label-placeholder="FullName"
      name="displayName"
      :placeholder="$t('FullName')"
      v-model="nameEN"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("displayName") }}</span>

    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      label-placeholder="Email"
      :placeholder="$t('Email')"
      v-model="email"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("email") }}</span>

    <vs-input
    icon-pack="feather"
      ref="password"
      type="password"
      data-vv-validate-on="blur"
      v-validate="'required|min:8|max:20'"
      id="Password"
      name="password"
      icon-no-border
      @input="checkPassword"
      label-placeholder="Password"
      :placeholder="$t('Password')"
      v-model="password"
      :icon-after="true"
      :icon="PasswordIcon"
      @icon-click="TextToPassword()"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>
    <ul class="ml-2 mt-3">
      <li v-bind:class="{ is_valid: contains_eight_characters }">
        8 {{ $t("Characters") }}
      </li>
      <li v-bind:class="{ is_valid: contains_number }">
        {{ $t("ContainsNumber") }}
      </li>
      <li v-bind:class="{ is_valid: contains_uppercase }">
        {{ $t("ContainsUppercase") }}
      </li>

      <li v-bind:class="{ is_valid: contains_lowercase }">
        {{ $t("ContainsLowercase") }}
      </li>
      <li v-bind:class="{ is_valid: contains_special_character }">
        {{ $t("ContainsSpecialCharacter") }}
      </li>
    </ul>

    <vs-input
      type="password"
       icon-pack="feather"
      ref="password"
      id="ConfirmPassword"
      :icon="PasswordConfirmIcon"
      @icon-click="TextToPasswordConfirm()"
      icon-no-border
      v-validate="'min:8|max:20|confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      :placeholder="$t('Confirmpassword')"
      v-model="confirm_password"
      class="w-full mt-6"
      :icon-after="true"
    />
    <span class="text-danger text-sm">{{
      errors.first("confirm_password")
    }}</span>

    <div class="vx-row">
      <div class="vx-col lg:w-3/5">
        <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6"
          >{{ $t("AcceptTermsConditions") }}
        </vs-checkbox>
      </div>
      <div class="vx-col lg:w-2/5 ">

        <a
        v-if="!$vs.rtl"
          target="_blank"
          style="width: 100px"
          :href="baseURL + 'files/TermsAndConditionsRegistration.pdf'"
          color="success"
        >
          <feather-icon
            icon="DownloadIcon"
            class="cursor-pointer mt-3"
          ></feather-icon>
          <span style="font-size: 0.95rem">{{ $t("TermsAndConditions") }}</span>
        </a>
      <br/>
        <a
        v-if="!$vs.rtl"
          target="_blank"
          style="width: 100px"
          :href="baseURL + 'files/Patient_Waiver.pdf'"
          color="success"
        >
          <feather-icon
            icon="DownloadIcon"
            class="cursor-pointer mt-3"
          ></feather-icon>
          <span style="font-size: 0.95rem">{{ $t("PatientWaiver") }}</span>
        </a>
 <a
    v-if="$vs.rtl"
          target="_blank"
          style="width: 100px"
          :href="baseURL + 'files/ArabDocliniaTerms480egp.pdf'"
          color="success"
        >
          <feather-icon
            icon="DownloadIcon"
            class="cursor-pointer mt-3"
          ></feather-icon>
          <span style="font-size: 0.95rem">{{ $t("TermsAndConditions") }}</span>
        </a>
        <br/>
        <a
        v-if="$vs.rtl"
          target="_blank"
          style="width: 100px"
          :href="baseURL + 'files/Patient_Waiver.pdf'"
          color="success"
        >
          <feather-icon
            icon="DownloadIcon"
            class="cursor-pointer mt-3"
          ></feather-icon>
          <span style="font-size: 0.95rem">{{ $t("PatientWaiver") }}</span>
        </a>
      </div>
    </div>
    <vs-checkbox v-model="isAcceptPatientAbove18Years" class="mt-6"
      >{{ $t("AcceptPatientAbove18Years") }}
    </vs-checkbox>
    <vs-button type="border" to="/pages/login" class="mt-6">{{$t("back")}}</vs-button>
    <vs-button
      class="float-right mt-6"
      @click="registerUserJWt"
      :disabled="!validateForm || !valid_password"
      >{{ $t("Register") }}</vs-button>
    <vs-popup title="" :active.sync="ShowSuccessPopup">
      <SuccessPopup
        :Message="$t('RegisterSuccsessMessage')"
        v-if="ShowSuccessPopup"
        @CloseSeccussPopup="CloseSeccussPopup"
        @closePop="this.$router.push('/pages/login')"
      ></SuccessPopup>
    </vs-popup>
  </div>
</template>

<script>
import SuccessPopup from "./SuccessPopup.vue";
import { domain } from "@/gloabelConstant.js";
export default {
  data() {
    return {
      PasswordIcon: "icon icon-eye-off",
      PasswordConfirmIcon:"icon icon-eye-off",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      ShowSuccessPopup: false,
      nameEN: "",
      email: "",
      password: "",
      UserName: "",
      confirm_password: "",
      userType: "",
      IsConfirmed: false,
      IsActive:false,
      isTermsConditionAccepted: false,
      isAcceptPatientAbove18Years: false,
      baseURL: domain,
    };
  },
  components: {
    SuccessPopup,
  },
  computed: {
    validateForm() {
      debugger;
      if (this.isResetPassword)
        return (
          this.password &&
          this.contains_eight_characters &&
          this.contains_number &&
          this.contains_uppercase &&
          this.contains_lowercase &&
          this.contains_special_character
        );
      else
        return (

          !this.errors.any() &&
          this.nameEN != "" &&
          this.email != "" &&
          this.password != "" &&
          this.UserName != "" &&
          this.confirm_password != "" &&
          this.isTermsConditionAccepted === true &&
          this.isAcceptPatientAbove18Years === true &&
          this.contains_eight_characters &&
          this.contains_number &&
          this.contains_uppercase &&
          this.contains_lowercase &&
          this.contains_special_character &&
          this.password == this.confirm_password
        );
    },
    UserType() {
      debugger;
      return this.$store.state.auth.UserType;
    },
  },
  methods: {
    TextToPasswordConfirm(){
      debugger;
      if (document.getElementById("ConfirmPassword").type == "text") {
        document.getElementById("ConfirmPassword").type = "password";
        this.PasswordConfirmIcon = "icon icon-eye-off";
      } else {
        document.getElementById("ConfirmPassword").type = "text";
        this.PasswordConfirmIcon = "icon icon-eye";
      }
    },
    TextToPassword() {
      debugger;
      if (document.getElementById("Password").type == "text") {
        document.getElementById("Password").type = "password";
        this.PasswordIcon = "icon icon-eye-off";
      } else {
        document.getElementById("Password").type = "text";
        this.PasswordIcon = "icon icon-eye";
      }
    },
    CloseSeccussPopup() {
      this.ShowSuccessPopup = false;
      this.$router.push("/pages/login");
    },
    checkPassword() {
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_lowercase = /[a-z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_lowercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;
      if (this.UserType != "Doctor" && this.UserType != "Hospital") {
        this.IsConfirmed = true;
        this.IsActive = true;
      }
      if (this.UserType == "Doctor" || this.UserType == "Hospital") {
        debugger
        this.IsActive = true;
      }
      debugger
      const payload = {
        userDetails: {
          nameEN: this.nameEN,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password,
          userType: this.UserType,
          IsConfirmed: this.IsConfirmed,
          UserName: this.UserName,
          IsActive:this.IsActive
        },
      };
      debugger;
      this.$store
        .dispatch("auth/registerUserJWT", payload)
        .then((res) => {
          debugger;
          this.ShowSuccessPopup = true;
          var messge = res.data.Message;
          console.log(messge);
        })
        .catch((error) => {
          debugger;
          this.$vs.notify({
            title: "regstration Attempt",
            text: error.response.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });

          debugger;
          console.log(error);
        });
    },
  },
};
</script>
<style>
.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons :lang(en) {
  font-family: unset;
}
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
