<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="w-full   bgd-img-maR items-center justify-center" :style="{'background-image':`url(${require('@/assets/images/pages/LoginBG.png')})`,'backgroundRepeat': 'no-repeat'}">
    <div class="vx-row items-center w-full h-screen no-gutter flex justify-center">
      <div
        class="vx-col sm:w-4/5 md:w-4/5 lg:w-3/4 xl:w-4/5 sm:m-0 items-center w-full no-gutter justify-center"
      >
        <div class="vx-row no-gutter justify-center pb-5 pt-4">
          <img
            src="@/assets/images/pages/logoN.png"
            style="max-width: 350px"
            alt="login"
          />
        </div>
         <div class="vx-row no-gutter justify-center">
          <p
            style="
              text-align: center;
              font: normal normal 100 30px/51px UbuntuRegular;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
            "
          >
            {{ $t("Signup") }}
            {{ "/" }} {{ $t("registerasanew") }}
            {{ "/" }}
               <P>
                {{$t(UserType)}}
                </P>
          </p>
        </div>
      </div>
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-2/5 sm:m-0 mx-auto self-center pb-8">
        <vx-card>
          <div
            slot="no-body"
            class="full-page-bg-color"
            style="
              background: transparent
                radial-gradient(closest-side at 50% 50%, #5dbeff 0%, #2f5f80 100%) 0% 0%
                no-repeat padding-box;
              opacity: 1;
            "
          >
            <div class="vx-row">
              <div
                class="vx-col sm:w-full md:w-full lg:w-2/3 mx-auto self-center d-theme-dark-bg"
              >
                <div class="px-8 register-tabs-container">
                  <vs-tabs>
                    <register-jwt></register-jwt>
                  </vs-tabs>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterJwt from "./RegisterJWT.vue";
//import RegisterFirebase from "./RegisterFirebase.vue"
//import RegisterAuth0 from "./RegisterAuth0.vue"

export default {
  data() {
    return {
       UserType:"Patient"
    };
  },
  components: {
    RegisterJwt,
    //RegisterFirebase,
    //RegisterAuth0
  },
  computed: {
    // UserType() {
    //   debugger;
    //   return this.$store.state.auth.UserType;
    // },

  },
  created(){
    if(this.$route.params.UserTypeID  ==3)
    this.UserType = "Hospital";
    else if(this.$route.params.UserTypeID  ==2)
    this.UserType = "Doctor";
  },
};
</script>

<style lang="scss">

.bgd-img-maR {
    background-image: url("../../../assets/images/pages/LoginBG.png");
    background-size: 100% 60%;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
  }
.mycard .vx-card {
  background: unset;
}
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
